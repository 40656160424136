/**
 * 用户接口
 */
export default SERVER => ({
  /**
   * 用户基本信息
   * method GET
   */
  USER_DETAIL: `${SERVER}/user/profile`,
  
});
