/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Modal } from 'antd';

// component
import LeftNav from '../components/LeftNav';

// utils
import { setToken, getUser } from '../utils/user';

// asset
import { ICONS, PICTURES } from '../assets';

import './style.scss';

const { confirm } = Modal;

const className = 'pageLayout';

class PageLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
  }

  // private
  showLogoutConfirm = () => {
    confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认是否退出？',
      cancelText: '取消',
      okText: '确定',
      onOk() {
        setToken('');
        window.location.href = '';
      },
      onCancel() {
      },
    });
  }

  // render
  renderHeader = ()=> {
    const account = getUser().userName || '';

    const ACCOUNT_MENU = (
      <Menu
        items={[
          {
            key: '1',
            label: (
              <div onClick={() => this.showLogoutConfirm()}>
                退出
              </div>
            ),
          },
        ]}
      />
    );

    return (
      <div className={`${className}-header-box`}>
        <div className={`${className}-logo-title-box`}>
          <img className={`${className}-logo-icon`} src={PICTURES.LOGO} />
          <div className={`${className}-title`}>天雀24小时无人值守系统-运营总后台</div>
        </div>

        <div />

        <Dropdown overlay={ACCOUNT_MENU}>
          <div className={`${className}-account-box`}>
            <img className={`${className}-head-icon`} src={ICONS.HEAD} />
            <div className={`${className}-name`}>{account}</div>
            <img className={`${className}-down-icon`} src={ICONS.ARROW_DOWN} />
          </div>
        </Dropdown>
      </div>
    );
  }

  render() {
    // 登录界面隐藏 菜单及header
    const isSigninPage = window.location.pathname == '/signin';

    return (
      <div className={`${className}`}>
        {isSigninPage?null:this.renderHeader()}
        <div className={`${className}-bottom-box`}>
          {isSigninPage?null:<LeftNav />}
          <div className={`${className}-content`}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  common: state.common,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLayout);
