import keyMirror from 'keymirror';
import { CALL_API } from '../../middleware/fetch';
import API from '../../middleware/apis';

export const ACTION_TYPES = keyMirror({
  COMMON_QINIU_TOKEN: null,
});

// 请求 七牛token
export const requestQiniuToken = () => {
  return {
    [CALL_API]: {
      type: ACTION_TYPES.COMMON_QINIU_TOKEN,
      url: API.COMMON_QINIU_TOKEN,
      method: 'GET',
    }
  };
}
