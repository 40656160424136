// -----------------------------
// 常量配置
// -----------------------------
import keyMirror from 'keymirror';

// eslint-disable-next-line
export const LOCAL_STORAGE = keyMirror({
  CONFIG: null,
  OP_ACCESS_TOKEN: null,
  OP_REFRESH_TOKEN: null,
  USER_INFO: null,

  // 门店信息
  STORE_ARRAY: null,
  STORE_ID: null, // 当前选择门店id
});

// 分页大小
export const PAGE_SIZE = 10;

// 七牛上传图片链接
export const QINIU_UPLOAD_URL = 'https://up-z2.qiniup.com';

// 图片 BASE_URL
export const IMAGE_BASE_URL = 'http://qiniu-image.wrzztech.top/';
