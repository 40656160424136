import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { injectReducer } from '../store/reducers';
import rootStore from '../store';

// --------------  登录  --------------
// 登录
const SignIn = lazy(() => import(/* webpackChunkName: "SignIn" */ './Auth/SignIn'));

// --------------  首页 - 门店管理  --------------
const Index = lazy(() => import(/* webpackChunkName: "Home" */ './Index/Index'));

// 404
const PageNotFound = lazy(() => import(/* webpackChunkName: "PageNotFound" */ './PageNotFound'));

const Conponents = {
  // 登录
  SignIn,

  // 首页 - 商户管理
  Index,

  PageNotFound,
};

// 查找reducer，component_name 到 path 的映射
const COMPONENT_PATH_MAPPING = {
  // 登录
  'SignIn': 'Auth/SignIn',

  // 首页-商户管理
  'Index': 'Index/Index',

  'PageNotFound': 'PageNotFound',
}

const AsyncComponent = props => {
  const {componentName} = props;

  import(`./${COMPONENT_PATH_MAPPING[componentName]}/reducer`).then(({default: reducer}) => {
    injectReducer(rootStore, {key: componentName, reducer});
  });

  const Component = Conponents[componentName];

  return <Component {...props} />;
};

AsyncComponent.propTypes = {
  componentName: PropTypes.string.isRequired
};

AsyncComponent.defaultProps = {};

export default AsyncComponent;
