/**
 * 公共接口
 */
export default SERVER => ({

  /**
   * 七牛token
   * method GET
   */
  COMMON_QINIU_TOKEN: `${SERVER}/manager/common/file/getToken`,

});