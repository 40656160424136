/**
 * 商家接口
 */
export default SERVER => ({

  /**
   * 商家列表
   * method GET
   */
  MERCHANT_LIST: `${SERVER}/merchant/manager/getList`,

  /**
   * 商家新增
   * method POST
   */
  MERCHANT_ADD: `${SERVER}/merchant/manager/add`,

  /**
   * 商家编辑
   * method POST
   */
  MERCHANT_EDIT: `${SERVER}/merchant/manager/edit`,

  /**
   * 商家删除
   * method POST
   */
  MERCHANT_DELETE: `${SERVER}/merchant/manager/delMerchant`,

  /**
   * 商家详情
   * method POST
   */
  MERCHANT_DETAIL: `${SERVER}/merchant/manager/get`,

  /**
   * 商家启用、禁用
   * method POST
   */
  MERCHANT_ENABLE: `${SERVER}/merchant/manager/modifyEnableState`,
  
});