/**
 * 菜单控件
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Menu } from 'antd';
import {
  HomeOutlined,
  FileTextOutlined,
  DashboardOutlined,
  BankOutlined,
  TeamOutlined,
  FileOutlined,
  AccountBookOutlined,
} from '@ant-design/icons';

// components

// actions

// utils

// constant

// style
import './style.scss';

const className = 'componentLeftNav';

const SubMenu = Menu.SubMenu;

// 商户菜单
const MENU_LIST = [{
  title: '商家管理',
  path: '/',
  icon: <HomeOutlined />
}
];

class LeftNav extends Component {
  getMenuNodes = menuList => {
    return menuList.reduce((pre, item) => {
      if (!item.items) {
        pre.push((
          <Menu.Item key={item.path} icon={item.icon}>
            <div onClick={() => window.location.href=item.path}>{item.title}</div>
          </Menu.Item>
        ))
      } else {
        pre.push((
          <SubMenu key={item.path} icon={item.icon} title={item.title}>{this.getMenuNodes(item.items)}</SubMenu>
        ))
      }
      return pre;
    }, []);
  }

  render() {
    const path = this.props.location.pathname;

    return (
      <div className={`${className}`}>
        <Menu
          mode='inline'
          theme='light'
          selectedKeys={[path]}
        >
          {this.getMenuNodes(MENU_LIST)}
        </Menu>
      </div>
    );
  }
}

export default withRouter(LeftNav);