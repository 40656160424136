import { ACTION_TYPES } from './action';

export const initialState = {
  requesting: false,
  qiniuToken: '',
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.COMMON_QINIU_TOKEN]: (state, action) => {
    const newState = {...state};
    newState.requesting = false;
    newState.qiniuToken = action.data;
    return newState;
  },
  [`REQUESTING_${ACTION_TYPES.COMMON_QINIU_TOKEN}`]: state => {
    const newState = {...state};
    newState.requesting = true;
    return newState;
  },
  [`FAIL_${ACTION_TYPES.COMMON_QINIU_TOKEN}`]: state => {
    const newState = {...state};
    newState.requesting = false;
    return newState;
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const reducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
