import common from './common';
import auth from './auth';
import user from './user';
import merchant from './merchant';

// ----------------------------------
// SERVER
// ----------------------------------
const SERVER_DEVELOPMENT = 'http://119.23.248.54:6001/manager';
// const SERVER_DEVELOPMENT = `https://www.wrzztech.top/manager`;
const SERVER_PRODUCTION = `https://www.wrzztech.top/manager`;

export const SERVER = `${__DEV__ ? SERVER_DEVELOPMENT : SERVER_PRODUCTION}`;

export default {
  ...common(SERVER),
  ...auth(SERVER),
  ...user(SERVER),
  ...merchant(SERVER),
};
